// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-condiciones-index-js": () => import("./../../../src/pages/condiciones/index.js" /* webpackChunkName: "component---src-pages-condiciones-index-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-cookies-index-js": () => import("./../../../src/pages/cookies/index.js" /* webpackChunkName: "component---src-pages-cookies-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-precios-index-js": () => import("./../../../src/pages/precios/index.js" /* webpackChunkName: "component---src-pages-precios-index-js" */),
  "component---src-pages-privacidad-index-js": () => import("./../../../src/pages/privacidad/index.js" /* webpackChunkName: "component---src-pages-privacidad-index-js" */)
}

